import React from 'react';
import '../footer/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer-quote">Empowering creativity, one moment at a time.</p>
      <p className="footer-p">© 2024 SeeSay Moments. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
